<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0">
              <div class="display-2 font-weight-light text-uppercase" style="width: 100%;">
                {{ $t('customerService.prospects.form.title') }}
              </div>
              <div class="subtitle-1 font-weight-light">
                {{ displayName(prospect) }} <a :href="'tel:' + phoneInt(prospect)" target="_blank" class="white--text">{{ displayPhone(prospect) }}</a>
              </div>
            </v-col>
          </v-row>
        </template>
        <v-container>
          <v-card class="main-card">
            <div class="pa-4">
              <v-row class="text-center">
                <v-col class="ma-0 pa-0">
                  <v-btn color="red" :loading="loading" :disabled="loading" @click="callProspect('suspend')">
                    {{ $t('customerService.prospects.form.button.suspend') }}
                  </v-btn>
                </v-col>
                <v-col class="ma-0 pa-0">
                  <v-btn color="orange" :loading="loading" :disabled="loading" @click="callProspect('retry')">
                    {{ $t('customerService.prospects.form.button.retry') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <h1 class="text-capitalize">
              {{ displaySource(prospect) }}
            </h1>
            <h3>{{ displayName(prospect) }} <a :href="'tel:' + phoneInt(prospect)" target="_blank">{{ displayPhone(prospect) }}</a></h3>
            <div class="mt-5" v-html="$t('customerService.prospects.form.description', {location: displaySource(prospect)}).replaceAll('\n', '<br>')" />
          </v-card>
        </v-container>
        <v-card-actions>
          <v-row class="text-center">
            <v-col class="ma-0 pa-0">
              <v-btn color="primary" :loading="loading" :disabled="loading" @click="callProspect('done')">
                {{ $t('customerService.prospects.form.button.done') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';

  export default {
    name: 'CallProspectForm',
    props: {
      prospect: {
        type: Object,
        required: false
      }
    },
    data () {
      return {
        show: false,
        loading: false,
        valid: true,
        discount: {}
      };
    },
    watch: {
      prospect: function () {
        this.show = this.prospect !== undefined;
      },
      show: function () {
        this.discount = {};
      }
    },
    methods: {
      displaySource (prospect) {
        switch (prospect?.source) {
          case 'lacreperiedelagare':
            return 'la Crêperie de la Gare (Sartrouville)';
          case 'creperielouis':
            return 'la Crêperie Louis (Orgeval)';
        }

        return '';
      },
      phoneInt (prospect) {
        if (prospect === undefined) {
          return '';
        }

        return phoneInt(prospect.phone);
      },
      displayPhone (prospect) {
        if (prospect === undefined) {
          return '';
        }

        return displayPhone(prospect.phone);
      },
      displayName (prospect) {
        if (prospect === undefined) {
          return '';
        }

        return prospect.firstName + ' ' + prospect.lastName;
      },
      async callProspect (status) {
        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'prospect/todo/call', {
            id: this.prospect.id,
            phone: this.prospect.phone,
            email: this.prospect.email,
            status: status
          });
          if (response.data !== undefined && response.data.discount !== undefined) {
            this.discount = response.data.discount;
            this.discount.valueType = this.discount.valueType === 'percentage' ? '%' : '€';
          }
          this.$emit('success', false);
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
        this.show = false;
      }
    }
  };
</script>
